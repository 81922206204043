<template>
  <b-card class="p-2">
    <b-link @click="goBack">
      <feather-icon
        class="position-absolute text-primary"
        size="40"
        icon="ArrowLeftCircleIcon"
      />
    </b-link>
    <b-row>
      <b-col
        class="mb-4 d-flex justify-content-center align-items-center"
        cols="12"
      >
        <feather-icon
          icon="BookIcon"
          size="25"
          class="mr-2"
        />
        <h1>Informazioni Contatto</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          ID Contatto:
        </p>
        <h3 class="mb-1">
          #{{ contactData.id }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Nome Contatto:
        </p>
        <h3 class="mb-1">
          {{ contactData.display_name }}
        </h3>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Email Contatto:
        </p>
        <h3 class="mb-1">
          {{ contactData.email }}
        </h3>
      </b-col>
      <b-col
        v-if="contactData.phone_number"
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Telefono Contatto:
        </p>
        <h3 class="mb-1">
          {{ contactData.phone_number }}
        </h3>
      </b-col>
      <b-col
        v-if="contactData.company"
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Azienda:
        </p>
        <h3 class="mb-1">
          {{ contactData.company }}
        </h3>
      </b-col>
      <b-col
        v-if="contactData.user"
        class="mb-2"
        cols="12"
        md="6"
        lg="6"
      >
        <p class="mb-1">
          Utente associato:
        </p>
        <h3 class="mb-1">
          <feather-icon
            icon="UserIcon"
            size="25"
            class="text-primary"
          />
          <b-link
            :to="{ name: 'apps-users-view', params: { id: contactData.user.id } }"
            class="font-weight-bold text-nowrap"
          >
            #{{ contactData.user.anagrafico_id }} - {{ contactData.user.display_name }}
          </b-link>
        </h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="text-right mt-2"
        cols="12"
      >
        <b-button
          :to="{ name: 'apps-contacts-edit', params: { id: contactData.id } }"
          variant="primary"
        >
          Modifica
        </b-button>
        <b-button
          variant="outline-danger"
          class="ml-1"
          @click="
            $bvModal.show('delete-modal')
            selectedContact = contactData.id
          "
        >
          Elimina
        </b-button>
      </b-col>
    </b-row>
    <b-modal
      id="delete-modal"
      title="Cancella Contatto"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      size="sm"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Cancella Contatto
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <p class="m-2">
        Sei sicuro di voler cancellare definitivamente questo Contatto?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            deleteContact(selectedContact)
          "
        >
          OK
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BLink, BModal,
} from 'bootstrap-vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BLink,
    BModal,
  },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedContact: null,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async reloadPage(parent_id) {
      this.$router.push('/')
      await new Promise(r => setTimeout(r, 100))
      this.$router.push(`/contatti/visualizza/${parent_id}`)
    },
    deleteContact(id) {
      store
        .dispatch('app-contacts/deleteContact', { id })
        .then(() => {
          this.selectedContact = null
          this.$router.replace({ name: 'apps-contacts-list' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Contatto #${id} eliminato con successo`,
                icon: 'BookIcon',
                variant: 'success',
              },
            })
          })
        })
        .catch(error => {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        })
    },
  },
}
</script>

<style>
.position-absolute {
  z-index: 1;
}
</style>
